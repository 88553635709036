@import "./colors";
@import "./grid";
@import "./variables";
@import "./overlay";

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';

:root {
  --height-header: 60px;
  --height-footer: 30px;
}

body {
  * {
    box-sizing: border-box;
  }
}

//  ------------------------------------------------ LIST -------------------------------------------------------- //

ul,
li {
  margin: 0;
}

//  ------------------------------------------------ SCROLL BAR -------------------------------------------------------- //

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: hsl(from var(--color-background-contrast) h s calc(l - 5));
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: hsl(from var(--color-background-contrast) h s calc(l - 15));
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(from var(--color-background-contrast) h s calc(l - 30));

}

.dark {

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: hsl(from var(--color-background-contrast) h s calc(l + 5));
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: hsl(from var(--color-background-contrast) h s calc(l + 20));
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: hsl(from var(--color-background-contrast) h s calc(l + 50));

  }
}
