:root {
  --font-family-base: Arial, Helvetica, sans-serif;

  --bs-button-border-radius: 4px;
  --bs-button-border: none;
  --bs-button-font-size: 16px;
  --bs-button-lg-line-height: 38px;
  --bs-button-lg-padding: 0 16px;
}

