@import "variables";

/*Brand Manual colors*/
// from white to black including gray shades
$color-white: #fff;
$color-gray1-lighter: #E6E6E6;
$color-gray2-light: #CCCCCC;
$color-gray3-middle: #999999;
$color-gray4-dark: #666666;
$color-gray5-darker: #333333;
$color-black: #000;

//main color
$color-bridgestone-red: #FF0000;

//tests
$bridgestone-red: hsla(0, 100%, 50%, 1);
$bridgestone-red: hsla(0, 100%, 75%, 1);
$bridgestone-red: hsla(0, 100%, 35%, 1);
$hsla-bridgestone-red-ligth: hsla($hue-red, $saturation-75, $lightness-75, $opacity-100);
$hsla-bridgestone-red-normal: hsla($hue-red, $saturation-75, $lightness-50, $opacity-100);
$hsla-bridgestone-red-darker: hsla($hue-red, $saturation-75, $lightness-35, $opacity-100);

//ruby colors
$color-ruby-dark: #A03652;
$color-ruby-light: #F1597D;
$color-ruby-lighter: #F58AA3;
$color-ruby-extra-light: #FAC5D1;

//iris colors
$color-iris-dark: #3D4C79;
$color-iris-light: #6076B4;
$color-iris-lighter: #8F9ECA;
$color-iris-extra-light: #C7CFE5;

//sky blue colors
$color-sky-dark: #00719C;
$color-sky-light: #00A8E5;
$color-sky-lighter: #4BBDEC;
$color-sky-extra-light: #ABD7F2;

//Sunlight colors
$color-sunlight-dark: #A5741B;
$color-sunlight-light: #FCB033;
$color-sunlight-lighter: #FEC463;
$color-sunlight-extra-light: #FEDAAB;

//Coral orange colors
$color-coral-dark: #A0470A;
$color-coral-light: #F46E21;
$color-coral-lighter: #F89E5B;
$color-coral-extra-light: #FCC7A3;

//Forest green colors
$color-forest-dark: #0F7A3A;
$color-forest-light: #30B459;
$color-forest-lighter: #68C079;
$color-forest-extra-light: #B4DBB8;

//Spring green colors
$color-spring-dark: #6A8A21;
$color-spring-light: #A7CE38;
$color-spring-lighter: #BAD874;
$color-spring-extra-light: #DBEAB8;

//Peacock green colors
$color-peacock-dark: #007975;
$color-peacock-light: #00B1AD;
$color-peacock-lighter: #5AC4BD;
$color-peacock-extra-light: #AFDEDC;

//to override bootstrap's values
:root {


  --red: $bridgestone-red;
  --danger: $bridgestone-red;
  --success: $color-forest-light;
  --warning: $color-sunlight-light;
  --secondary: $color-gray2-light;
}

$theme-colors: ("primary": $color-sky-light,
        "red": $color-bridgestone-red,
        "danger": $color-bridgestone-red,
        "warning": $color-sunlight-light,
        "secondary": $color-gray2-light);

/*Custom colors*/
$color-gray: #424242;
$color-dark-gray: #212121;
$color-darker-gray: #121212;
/* Overriding Bootstrap variables to assign Bridgestone colours*/
$color-primary: $color-white; // required value
$color-danger-primary: $color-bridgestone-red;
$color-danger-secondary: #ae2e29; //custom color
$color-warning-primary: $color-sunlight-light;
$color-success-primary: $color-forest-light;
$color-info-primary: $color-sky-light;
$blue: $color-sky-dark;
$indigo: $color-iris-dark;
$purple: $color-iris-light;
$pink: $color-ruby-light;
$red: $color-bridgestone-red;
$danger: $color-bridgestone-red;
$orange: $color-coral-light;
$yellow: $color-sunlight-light;
$green: $color-forest-light;
$teal: $color-peacock-light;
$cyan: $color-sky-lighter;


:root {

  --color-primary: #{$color-bridgestone-red};

  --color-text: #{$color-dark-gray};
  --color-text-sub: #{$color-gray4-dark};

  --color-icon: #{$color-gray4-dark};
  --color-icon-hover: #{$color-white};

  --color-background: #F6F6F6;
  --color-background-contrast-light: #{$color-gray1-lighter};
  --color-background-contrast: #{$color-gray2-light};
  --color-background-contrast-hover: #{$color-gray3-middle};

  --color-table-background: #{$color-gray2-light};

  --color-button-background-default: #{$color-gray4-dark};

  --color-success: #{$color-success-primary};
  --color-danger: #{$color-danger-primary};
  --color-warning: #{#ef9604};
  --color-info: #{$color-info-primary};
}

body.dark {
  --color-primary: #{$color-bridgestone-red};

  --color-text: #{$color-white};
  --color-text-sub: #{$color-gray3-middle};

  --color-icon: #{$color-gray3-middle};
  --color-icon-hover: #{$color-white};

  --color-background: #{$color-gray};
  --color-background-contrast: #{$color-dark-gray};
  --color-background-contrast-hover: #{$color-gray5-darker};
  --color-background-contrast-light: #{$color-gray5-darker};

  --color-table-background: #{$color-gray2-light};

  --color-button-background-default: #{$color-gray4-dark};


  --color-success: #{$color-success-primary};
  --color-danger: #{$color-danger-primary};
  --color-warning: #{$color-warning-primary};
  --color-info: #{$color-info-primary};
}
