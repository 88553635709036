$value: 30;
$size: $value * 1px;

//layer position with z-index
$z-index-content: 3;
$z-index-table-header: 5;
$z-index-modal: 30;
$z-index-sidenav: 100;

//heights
$height-brand-navbar: 60px;
$height-tabs: 60px; //for incoming and outgoing
$height-notification-badge: 25px; //for the sidebar
$height-footer: 30px;

//widths
$width-sidebar: 200px;
$width-notification-badge: 25px;

//border radius

//font
$font-type: sans-serif; //Specific CSS values (identifiers) such as initial or sans-serif require not to be quoted

//colors attributes
/*
    The Hue Property is a color picked from a palette of 360 colors.
    The colors are set in a circle, and each hue is a degree in the color-wheel.
    The chosen color is the base from which we’ll create other shades by adding saturation, lightness, or both.
*/
$hue-red : 0;

/*
    The Saturation Property describes the color’s saturation.
    0% means no color (the color will be grey), and a value of 100% will show the color in its most vibrant color.
    It’s best to use a value in the range of 10% — 100% since that will allow us to see the base color.
*/
$saturation-75: 70%;
$saturation-50: 50%; /* mid range - normal */
$saturation-25: 35%;
$saturation-10: 10%;

/*
    The Lightness Property describes the lighting intensity.
    The extreme values will eliminate the color: 0% will look black, and 100% will look white.
    Therefore we prefer to use numbers between 10% and 90% since that will allow us to see the base color.
*/
$lightness-75: 75%;
$lightness-50: 50%; /* mid range - normal */
$lightness-35: 35%;

//opacity : for text, rgb and HSLA colors
/*
    The Alpha Channel Property (optional) — using HSLA instead of HSL allows us to control the color’s opacity.
    It’s best to use values in the range of 10% — 100% since that will enable us to see the base color (0% means transparent color).
*/
//HSLA part
$opacity-100: 100%; /* full opacity - normal */
$opacity-75 :  75%;
$opacity-50 :  50%; /* half opacity, half transparency */
$opacity-25 :  25%;
$opacity-0  :   0%; /* no opacity, full transparency */

//for text, rgb, background, etc.
$opacity-full: 1;
$opacity-almost-full-95: 0.95;
$opacity-almost-full-75: 0.75;
$opacity-half: 0.5;
$opacity-none: 0;