// --------------------------------- BASICS -------------------------------------- //


// $--color-primary: #f59d54;
$--color-primary: #ff0000;
$--color-primary-hover: darken($--color-primary, 5%);
$--color-primary-active: darken($--color-primary, 10%);
$--color-white: #ffffff;
$--color-black: #000000;

// --------------------------------- BACKGROUNDS -------------------------------------- //


$--color-background: #eeeeee;
$--color-background-hover: darken($--color-background, 3%);
$--color-background-active: darken($--color-background, 10%);

$--color-background-contrast: #dddddd;
$--color-background-contrast-hover: darken($--color-background-contrast, 5%);
$--color-background-contrast-active: darken($--color-background-contrast, 10%);

// --------------------------------- HEADER -------------------------------------- //

$--color-header-background: #000000;
$--color-header-text: #ffffff;


// --------------------------------- COMPONENTS -------------------------------------- //


// --------------------------------- INFO / WARNING / DANGER / SUCCESS -------------------------------------- //
$--color-success: #03b103;
$--color-success-hover: darken($--color-success, 5%);
$--color-success-active: darken($--color-success, 10%);

$--color-info: #0099d5;
$--color-info-hover: darken($--color-info, 5%);
$--color-info-active: darken($--color-info, 10%);

$--color-warning: #d8c625;
$--color-warning-hover: darken($--color-warning, 5%);
$--color-warning-active: darken($--color-warning, 10%);

$--color-danger: #d82528;
$--color-danger-hover: darken($--color-danger, 5%);
$--color-danger-active: darken($--color-danger, 10%);


// ----------------------------------------------- CSS VARIABLES ------------------------------------------------ //

:root {

  // --------------------------------- CSS VARIABLES => BASICS -------------------------------------- //

  --color-primary: #{$--color-primary};
  --color-primary-text: #{$--color-white};
  --color-primary-hover: #{$--color-primary-hover};
  --color-primary-active: #{$--color-primary-active};
  --color-white: #{$--color-white};
  --color-black: #{$--color-black};

  // --------------------------------- CSS VARIABLES => BACKGROUNDS -------------------------------------- //


  --color-background: #{$--color-background};
  --color-background-hover: #{$--color-background-hover};
  --color-background-active: #{$--color-background-active};
  --color-background-contrast: #{$--color-background-contrast};
  --color-background-contrast-hover: #{$--color-background-contrast-hover};
  --color-background-contrast-active: #{$--color-background-contrast-active};
  --color-border-highlight: var(--color-primary);
  --color-border-normal: rgba(0, 0, 0, .1);

  // --------------------------------- HEADER -------------------------------------- //

  --color-header-background: #{$--color-header-background};
  --color-header-text: #{$--color-white};


  // --------------------------------- CSS VARIABLES => COMPONENTS -------------------------------------- //

  --background-color-disabled: rgba(221, 221, 221, 0.4);


  // --------------------------------- CSS VARIABLES => TEXT -------------------------------------- //
  --color-text: #333333;
  --color-text-sub: #666666;
  --color-text-disabled: #999999;


  // --------------------------------- CSS VARIABLES => INFO / WARNING / DANGER / SUCCESS -------------------------------------- //
  --color-success: #{$--color-success};
  --color-success-hover: #{$--color-success-hover};
  --color-success-active: #{$--color-success-active};

  --color-info: #{$--color-info};
  --color-info-hover: #{$--color-info-hover};
  --color-info-active: #{$--color-info-active};

  --color-warning: #{$--color-warning};
  --color-warning-hover: #{$--color-warning-hover};
  --color-warning-active: #{$--color-warning-active};

  --color-danger: #{$--color-danger};
  --color-danger-hover: #{$--color-danger-hover};
  --color-danger-active: #{$--color-danger-active};
}

body.dark {

  // --------------------------------- CSS VARIABLES DARK => BASICS -------------------------------------- //

  --color-primary: #{$--color-primary};
  --color-white: #{$--color-white};
  --color-black: #{$--color-black};

  // --------------------------------- CSS VARIABLES DARK => BACKGROUNDS -------------------------------------- //


  --color-background: #424242;
  --color-background-hover: hsl(from #424242 h s calc(l + 5));
  --color-background-active: hsl(from #424242 h s calc(l + 10));
  --color-background-contrast: #212121;
  --color-background-contrast-hover: #333;
  --color-background-contrast-active: hsl(from #212121 h s calc(l - 10));


  --color-border-normal: rgba(255, 255, 255, 0.3);

  // --------------------------------- CSS VARIABLES DARK => HEADER -------------------------------------- //

  --color-header-background: #{$--color-header-background};
  --color-header-text: #{$--color-white};


  // --------------------------------- CSS VARIABLES DARK => COMPONENTS -------------------------------------- //

  --background-color-disabled: #999999;


  // --------------------------------- CSS VARIABLES DARK => TEXT -------------------------------------- //


  --color-text: white;
  --color-text-sub: #DDDDDD;

  --color-text-disabled: #CCCCCC;
}
