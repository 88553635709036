.bs-modal-overlay {
  background: rgba(black, 0.2);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.bs-loading-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(black, 0.2);
  border-radius: 5px;
}
