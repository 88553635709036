@import "variables", "colors";


//* { outline: 1px solid #f00 !important; } //for CSS scrolling debug : find out easily which elements takes too much space

html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  background-color: $color-dark-gray;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.grid_wrapper {
  clear: both; //for float element like tabs or brand navbar
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 1rem;
  height: calc(100% - #{$height-tabs});
  overflow-y: auto;
  /* not scroll so it only applies when needed */
  padding: 0.6rem;
  align-items: stretch;
}

@media screen and (max-width: 960px) {
  .wrapper {
    grid-template-columns: repeat(50% 50% 1fr);
    max-width: 960px;
  }
}

p {
  text-transform: none;
  font-size: 12px;
  margin: 1px;
}


.item-edit:hover {
  color: $color-warning-primary !important;
}

.item-remove:hover,
.item-disable:hover {
  color: $color-danger-primary !important;
}

.item-enable:hover {
  color: $color-success-primary !important;
}

a {
  color: $color-gray2-light;

  &:hover {
    color: $color-danger-primary;
    text-decoration: underline;
  }
}

.chartContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* LOGO B-MARK INSIDE SPINNER */
.loading-text {
  top: 50% !important;
}

//  ------------------------------------------------ SCROLL BAR -------------------------------------------------------- //

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: darken($color-gray2-light, 5%);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: darken($color-gray2-light, 20%);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: darken($color-gray2-light, 50%);
}

.bold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

mat-expansion-panel-header.mat-expansion-panel-header {
  border-radius: 0;
}
